import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const MobileNavMenu = () => {
  const { t } = useTranslation();
  const { megaMenuList } = useSelector((state) => state.common);

  const buildMenuHierarchy = (menuList) => {
    const menuMap = {};
    const addedToHierarchy = new Set();

    menuList?.forEach((menu) => {
      menuMap[menu.menu] = { ...menu, subMenus: [] };
    });

    const rootMenus = [];

    menuList?.forEach((menu) => {
      if (
        menu.parentMenu &&
        menu.parentMenu !== menu.menu &&
        menuMap[menu.parentMenu]
      ) {
        menuMap[menu.parentMenu].subMenus.push(menuMap[menu.menu]);
        addedToHierarchy.add(menu.menu);
      } else if (!addedToHierarchy.has(menu.menu)) {
        rootMenus.push(menuMap[menu.menu]);
        addedToHierarchy.add(menu.menu);
      }
    });

    const sortMenuItemsRecursively = (menus) => {
      return menus
        .sort((a, b) => a.displayPreference - b.displayPreference)
        .map((menu) => ({
          ...menu,
          subMenus: sortMenuItemsRecursively(menu.subMenus),
        }));
    };

    return sortMenuItemsRecursively(rootMenus);
  };

  const nestedMenuList = buildMenuHierarchy(megaMenuList);

  const renderMenu = (menu) => {
    const isExternal = /^https?:\/\//.test(menu?.link);

    return (
      <li key={menu.id} className="menu-item-has-children">
        {isExternal ? (
          <a href={menu?.link} rel="noopener noreferrer">
            {menu?.menu}
          </a>
        ) : (
          <Link to={menu?.link}>{menu?.menu}</Link>
        )}
        {menu.subMenus.length > 0 && (
          <ul className="sub-menu">
            {menu?.subMenus.map((subMenu) => renderMenu(subMenu))}
          </ul>
        )}
      </li>
    );
  };

  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/"}>{t("home")}</Link>
        </li>

        {nestedMenuList.map((menu) => renderMenu(menu))}

        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/"}>{t("Account")}</Link>
          <ul className="sub-menu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/my-account"}>
                {t("my_account")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/login-register"}>
                {t("login_register")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/about"}>{t("about_us")}</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/contact"}>
                {t("contact_us")}
              </Link>
            </li>
          </ul>
        </li>

        {/* Blog section */}
        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/blog-standard"}>
            {t("blog")}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
