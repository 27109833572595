import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import AppConsts from "../../appconst";


export const socialmedialists = createAsyncThunk("socialmedialist", async (_, thunkApi) => {

    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/SocialMedia/GetAllSocialMediaList`,
        {
          params: {
            companyId: localStorage.getItem("companyId"),
          },
          headers: {
            Accept: "application/json",
          },
        },
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  });
  
  const socialmediaSlice = createSlice({
    name: "socialmedia",
    initialState: {
      loading: false,
      socialmediaList: [], // Corrected name
    },
    extraReducers: {
      [socialmedialists.pending]: (state) => {
        state.loading = true;
      },
      [socialmedialists.fulfilled]: (state, action) => {
        state.loading = false;
        state.socialmedialist = action.payload; // Corrected name
      },
      [socialmedialists.rejected]: (state) => {
        state.loading = false;
      },
    },
  });
  
  
  export const thunks = {
    socialmedialists
  };
  export default socialmediaSlice.reducer;
  

  