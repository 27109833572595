import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import productReducer from './slices/product-slice';
import currencyReducer from './slices/currency-slice';
import cartReducer from './slices/cart-slice';
import compareReducer from './slices/compare-slice';
import wishlistReducer from './slices/wishlist-slice';
import commonReducer from './slices/common-slice';
import promotionReducer from './slices/promotion-slice';
import addressReducer from './slices/address-slice';
import checkoutReducer from './slices/checkout-slice';
import authReducer from './slices/auth-slice';
import ratingReducer from './slices/rating-slice';
import offerReducer from './slices/offer-slice';
import companiesReducer from './slices/companies-slice';
import footersReducer from './slices/footers-slice';
import promotionBannerListReducer from './slices/promotion-banner-slice';
import brandReducer from './slices/brand-slice';
import blogReducer from './slices/blog-slice';
import fulfillmentReducer from './slices/fullfilment-slice';
import userReducer from './slices/user-slice';
import themeSetupReducer from './slices/theme-setup-slice';
import loyaltyReducer from './slices/loyalty-slice';
import socialMediaReducer from './slices/socialmedia-slice';
const persistConfig = {
    key: "flone",
    version: 1.1,
    storage,
};

export const rootReducer = combineReducers({
    loyalty: loyaltyReducer,
    product: productReducer,
    currency: currencyReducer,
    cart: cartReducer,
    compare: compareReducer,
    wishlist: wishlistReducer,
    common: commonReducer,
    promotion: promotionReducer,
    address: addressReducer,
    checkout: checkoutReducer,
    auth: authReducer,
    ratings: ratingReducer,
    offer: offerReducer,
    companies: companiesReducer,
    footers: footersReducer,
    promotionbanner: promotionBannerListReducer,
    brand: brandReducer,
    blog: blogReducer,
    fulfillment: fulfillmentReducer,
    user: userReducer,
    theme: themeSetupReducer,
    socialmedia:socialMediaReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
});

export const persistor = persistStore(store);