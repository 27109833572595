import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Modal } from 'antd';
import dayjs from 'dayjs';
import LayoutOne from "../../layouts/LayoutOne";
import { fulfillmentStatus, updateFulfillment } from "../../store/slices/fullfilment-slice";
import { fetchOrderList } from "../../store/slices/checkout-slice";
import { useParams } from "react-router-dom";
import { Tooltip } from 'antd';
import cogoToast from 'cogo-toast';
import AppConsts from "../../appconst";

const OrderFulfillmentStatus = () => {

    const dispatch = useDispatch();
    let { id } = useParams();
    let forwardStatus;
    let reversalStatus;
    let trackingDetails;
    let unfulfillmentQuantity;
    let fulfillmentQuantity;
    let fulfillmentReference;
    const { fulfillmentList } = useSelector((state) => state.fulfillment);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedFulfillmentReference, setSelectedFulfillmentReference] = useState(null);
    const [getFulfillmentList, setFulfillmentList] = useState([]);
    const [orderStatus, setOrderStatus] = useState();

    function setGlobalVariables(record) {
        forwardStatus = record.forwardStatus;
        reversalStatus = record.reversalStatus;
        trackingDetails = record.trackingDetail;
        unfulfillmentQuantity = record.unfulfillmentQuantity;
        fulfillmentQuantity = record.fulfillmentQuantity;
        fulfillmentReference = record.fulfillmentReference;
    }
    const formatDate = (dateString) => {
        const formattedDate = dayjs(dateString).format(AppConsts.DATE_FORMAT_MMMM_D_COMMA_YYYY_AT_H_MM_A);
        return formattedDate;
    };
    const getOrderStatusText = (unfulfillmentQuantity, fulfillmentQuantity) => {
        if (parseInt(unfulfillmentQuantity) === 0) {
            return 'Closed';
        } else if (parseInt(unfulfillmentQuantity) < parseInt(fulfillmentQuantity)) {
            return 'In Progress';
        } else {
            return 'Open';
        }
    };

    useEffect(() => {
        if (fulfillmentList.length > 0) {
            setFulfillmentList(fulfillmentList)
        }
    }, [fulfillmentList])

    useEffect(() => {
        dispatch(fulfillmentStatus(id));
    }, [id])

    const handleCancelOrder = async () => {
        const data = {
            refundReturnStatus: 'COR',
            fulfillmentReference: selectedFulfillmentReference,
            fulfillmentItems: [
                {
                    fulfillmentReference: selectedFulfillmentReference,
                    refundReturnStatus: 'COR'
                }
            ]
        };

        try {

            const resultAction = await dispatch(updateFulfillment(data));
            if (updateFulfillment.fulfilled.match(resultAction)) {
                dispatch(fulfillmentStatus(id));
                cogoToast.success('Order canceled Request successfully.');
            } else {
                cogoToast.success('Please try again..!');
            }

        } catch (error) {
            cogoToast.error(`Failed to cancel order`);
        } finally {
            setIsModalVisible(false);
            dispatch(fetchOrderList());
        }
    };
    const showModal = (fulfillmentReference) => {
        setSelectedFulfillmentReference(fulfillmentReference);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        { title: 'Product Name', dataIndex: 'productName'},
        { title: 'Quantity', dataIndex: 'quantity'},
        {
            title: 'Date',
            dataIndex: 'orderDate',
            render: (orderDate) => formatDate(orderDate),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => {
                setGlobalVariables(record);
                setOrderStatus(getOrderStatusText(unfulfillmentQuantity, fulfillmentQuantity));

    const getColor = (status, isForward) => {
        let color = '';
        if (isForward) {
            switch (status) {
                case 'Pending':
                        color = '#ffcc00'; // Yellow
                         break;
                case 'Confirmed':
                        color = '#52c41a'; // Green
                         break;
                case 'Rejected':
                        color = '#f5222d'; // Red
                         break;
                case 'Ready To Pickup':
                        color = '#2db7f5'; // Blue
                         break;
                case 'Tracking Added':
                        color = '#1890ff'; // Light blue
                         break;
                case 'Delivered':
                        color = '#87d068'; // Light green
                         break;
                case 'Cancelled Order':
                        color = '#f5222d'; // Red
                        break;
                default:
                        color = '#000000'; // Black
            }
        } else {
            switch (status) {
            case 'Return Request':
                    color = '#722ed1'; // Purple
                        break;
            case 'Return Approve':
                    color = '#12b886'; // Green
                        break;
            case 'Return Reject':
                    color = '#eb2f96'; // Pink
                        break;
            case 'Return Tracking Added':
                    color = '#1890ff'; // Blue
                        break;
            case 'Return Received':
                    color = '#faad14'; // Gold
                        break;
            case 'Cancel Product Return Received':
                    color = '#faad14'; // Gold
                        break;
            case 'Return Pending':
                    color = '#722ed1'; // Purple
                        break;
            case 'Ready To Return':
                    color = '#1890ff'; // Blue
                        break;
            case 'Returned':
                    color = '#52c41a'; // Lime
                        break;
            case 'Refund Request':
                color = '#fa8c16'; // Orange
                    break;
            case 'Refund Approved':
                    color = '#13c2c2'; // Cyan
                        break;
            case 'Refund Rejected':
                    color = '#eb2f96'; // Pink
                        break;
            case 'Refunded':
                    color = '#52c41a'; // Lime
                        break;
            case 'Change Product Request': // Volcano
                    color = '#ff7a45';
                        break;
            case 'Change Product Approve':
                    color = '#722ed1'; // Purple
                        break;
            case 'Cancel Product Request': // Volcano
                   color = '#ff7a45';
                       break;
            case 'Cancel Product Approve':
                    color = '#722ed1'; // Purple
                        break;
            case 'Product Return Refund Reject':
                    color = '#722ed1'; // Purple
                        break;
            case 'Without Product Return Refund Request':
                    color = '#fa8c16'; // Orange
                        break;
            case 'Without Product Return Refund Approve':
                    color = '#faad14';// Gold
                        break;
            case 'Without Product Return Refund Reject':
                    color = '#eb2f96'; // Pink
                        break;
            case 'Refund Product Pending Return':
                    color = '#ff4d4f'; // Red
                        break;
            case 'Refund Product Ready To Return':
                    color = '#52c41a'; // Lime
                        break;
            case 'Refund Product Return Intransit':
                    color = '#1890ff'; // Blue
                        break;
            case 'Refund Product Return Received':
                    color = '#13c2c2'; // Cyan
                        break;
            case 'Cancel product Return Tracking Added': 
                    color = '#1890ff'; // Blue
                        break;
            case 'Cancel Order Request':
                color = '#fa8c16'; // Orange
                    break;
            case 'Cancel Order Rejected':
                color = '#faad14';// Gold
                    break;
            case 'Cancel Order Approved':
                    color = '#faad14';// Gold
                        break;
            default:
                    color = '#000000'; // Black
            }
        }
        return color;
    };

    const statusToShow = reversalStatus ? reversalStatus : forwardStatus;
    if (statusToShow === 'Tracking Added' && trackingDetails && trackingDetails.length > 0) {
        return (
            <div>
                <div style={{ color: getColor(statusToShow, !reversalStatus), fontWeight: 'bold' }}>
                    {statusToShow}
                </div>
                <ul>
                {trackingDetails.map((track, index) => (
                            <li key={track.trackingId}>
                                <Tooltip title="Click here to track the order">
                                    <a
                                        href={`https://${track.trackingURL}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: 'darkblue', textDecoration: 'underline' }} 
                                    >
                                      {track.trackingId}
                                    </a>
                                </Tooltip>
                            </li>
                        ))}
                </ul>
            </div>
        );
    }

    return (
        <div style={{ color: getColor(statusToShow, !reversalStatus), fontWeight: 'bold' }}>
            {statusToShow}
        </div>
    );
            },
        }
        // {
        //     title: 'Action',
        //     dataIndex: 'action',
        //     render: (orderDate) => (
        //         <EditOutlined style={{ color: '#1890ff', fontSize: '16px', pointer: 'cursor' }} />
        //     ),
        // },
    ];


    return (
        <>
            {/* <Header /> */}
            {/* <Navbar /> */}
            <Fragment>
                {/* <SEO
        titleTemplate="Order History"
        description="Order history page of ecommerce react minimalist eCommerce template."
      /> */}

                <LayoutOne headerTop="visible">
                    <div className="cart-main-area pt-50 pb-50 ">
                        <div className="container">
                            {getFulfillmentList && getFulfillmentList.length >= 1 ? (
                                <Fragment>
                                    <div className="mb-4 ">
                                        <h3 className="heading-bg-title">Fulfillment Status</h3>
                                    </div>
                                    <div className="mb-4">
                                        <Link to={process.env.PUBLIC_URL + "/order-history"} style={{ textDecoration: 'none', color: 'white', background: "#504a4a", padding: "10px", borderRadius: "9px" }}>
                                            Back to orders
                                        </Link>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-content table-responsive">
                                                <Table className="auth-ant-table" columns={columns} dataSource={getFulfillmentList} rowKey="id"
                                                />
                                            </div>
                                            {(orderStatus === 'Open' &&  fulfillmentList.every(item => item.reversalStatus !== 'Cancel Order Request')) && (
                                                <div style={{ display: 'flex', marginTop: '8px' }}>
                                                    
                                                    <button
                                                        onClick={() => showModal(fulfillmentReference)}
                                                        style={{
                                                            color: '#000', 
                                                            textDecoration: 'underline', 
                                                            textDecorationColor: 'black', 
                                                            border: 'none',
                                                            backgroundColor: 'transparent',
                                                        }}
                                                    >
                                                        Cancellation
                                                    </button>
                                                    <Modal
                                                        title="Confirm Cancellation"
                                                        visible={isModalVisible}
                                                        onOk={handleCancelOrder}
                                                        onCancel={handleCancel}
                                                        okText="Yes, Cancel Order"
                                                        cancelText="No"
                                                    >
                                                        <p>Are you sure you want to cancel this order?</p>
                                                    </Modal>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Fragment>
                            ) : (
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="item-empty-area text-center">
                                            <div className="item-empty-area__icon mb-30">
                                                <i className="pe-7s-cart"></i>
                                            </div>
                                            <div className="item-empty-area__text">
                                                No status found <br />{" "}
                                                <div className="text-center">
                                                    <Link
                                                        to={process.env.PUBLIC_URL + "/"}
                                                    >
                                                        Shop now
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                </LayoutOne>
            </Fragment>
            {/* <Footer /> */}
        </>
    );
};

export default OrderFulfillmentStatus;