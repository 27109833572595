import clsx from "clsx";
import cogoToast from 'cogo-toast';
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchAllCartList } from "../../store/slices/cart-slice.js";
import { setCheckoutProducts } from "../../store/slices/checkout-slice.js";
import { fetchPageHeaderDetails, resetState } from "../../store/slices/common-slice";
import { fetchSuggestBySearch } from '../../store/slices/product-slice';
import MenuCart from "./sub-components/MenuCart";

const IconGroup = ({ iconWhiteClass }) => {
  const navigate = useNavigate();
  const { search } = useParams();
  const { pathname } = useLocation();
  const [query, setQuery] = useState(search || '');
  const [suggestions, setSuggestions] = useState([]);
  const [isSuggestions, setIsSuggestions] = useState(false);
  const { filterSuggest } = useSelector((state) => state.product);
  const searchBarRef = useRef(null);
  const { themeSetups } = useSelector((state) => state.theme);
  const dispatch = useDispatch();


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
        setQuery('');
        setSuggestions([]);
        setIsSuggestions(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (filterSuggest.length > 0) {
      setSuggestions(filterSuggest.slice());
      setIsSuggestions(false);
    } else {
      setSuggestions([])
      setIsSuggestions(true);
    }
  }, [filterSuggest])

  const handleSubmit = e => {
    e.preventDefault();
    navigate(`${process.env.PUBLIC_URL}/search/${query}`);
  };
  const logOut = () => {
    localStorage.removeItem("isAccessToken", '');
    localStorage.removeItem("accessToken", '');
    localStorage.removeItem("refreshToken", '');
    localStorage.removeItem("userId");
    dispatch(resetState());
    dispatch(setCheckoutProducts([]));
    dispatch(fetchAllCartList())
    dispatch(fetchPageHeaderDetails())
    navigate(process.env.PUBLIC_URL + "/login-register");
    cogoToast.warn("Logout sucessfully", { position: "top-center" })
  }

  const onLogout = () => {
    navigate(process.env.PUBLIC_URL + '/home');
    cogoToast.success("logged out succesfully", { position: "top-center" });
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value.trim() === '') {
      setSuggestions([]);
      return;
    }
    const timeoutId = setTimeout(() => {
      dispatch(fetchSuggestBySearch(value.trim()));
    }, 300);
    return () => clearTimeout(timeoutId);
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    navigate(`${process.env.PUBLIC_URL}/search/${suggestion}`);
    setSuggestions([]);
  };

  const dropdownRef = useRef(null);
  const handleClickProfile = (e) => {
    if (dropdownRef.current) {
      dropdownRef.current.classList.toggle("active");
    }
    const accessToken = localStorage.getItem("isAccessToken") == 'true';
    if (!accessToken) {
      navigate('/login-register');
    }
  };

  const handleClick = e => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };
  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };
  const { compareItems } = useSelector((state) => state.compare);
  const { pageHeaderDetails } = useSelector((state) => state.common);

  const { t } = useTranslation();

  return (
    <div className={clsx("header-right-wrap", iconWhiteClass)} >
      <div className="same-style header-search d-none d-lg-block">
        <button className="search-active" onClick={e => handleClick(e)}>
          <i className={themeSetups[0]?.iconSetupSearch || "pe-7s-search"} />
        </button>
        <div ref={searchBarRef} className="search-content">
          <form onSubmit={handleSubmit}>
            <input type="text" placeholder={t("search")} aria-label="Search"
              className='search-input'
              onChange={handleInputChange}
              value={query} />
            <button type="submit" className="button-search">
              <i className={themeSetups[0]?.iconSetupSearch || "pe-7s-search"} />
            </button>
          </form>
          {suggestions?.length > 0 ? (
            <ul className="list-group position-absolute start-0 mt-2">
              {suggestions?.map((suggestion, index) => (
                <li
                  key={index}
                  className={clsx("list-group-item pointer")}
                  onClick={() => handleSuggestionClick(suggestion)}
                  style={{ cursor: 'pointer' }}
                >
                  {suggestion.split(' ').slice().join(' ')}
                </li>
              ))}
            </ul>
          ) : (isSuggestions && (
            <ul className="list-group position-absolute start-0 mt-2">
              <li
                className={clsx("list-group-item pointer")}
                style={{ cursor: 'pointer' }}
              >
                {"No Product Available..!"}
              </li>
            </ul>))}
        </div>
      </div>

      {/* <div className="same-style header-compare">
        <Link to={process.env.PUBLIC_URL + "/compare"}>
          <i className="pe-7s-shuffle" />
          <span className="count-style">
            {compareItems && compareItems.length ? compareItems.length : 0}
          </span>
        </Link>
      </div> */}
      <div className="same-style header-wishlist">
        <Link to={process.env.PUBLIC_URL + "/wishlist"}>
          <i className={themeSetups[0]?.iconSetupWishlist || "pe-7s-like"} />
          <span className="count-style">
            {pageHeaderDetails?.wishListCount ? pageHeaderDetails.wishListCount : 0}
          </span>
        </Link>
      </div>
      <div className="same-style cart-wrap ">
        <button className="icon-cart" onClick={e => navigate(process.env.PUBLIC_URL + "/cart")}>
          <i className={themeSetups[0]?.iconSetupCart || "pe-7s-shopbag"} />
          <span className="count-style">
            {pageHeaderDetails?.cartCount ? pageHeaderDetails.cartCount : 0}
          </span>
        </button>
        <MenuCart />
      </div>
      <div className="same-style account-setting">
        <button
          className="account-setting-active"
          onClick={e => handleClickProfile(e)}
        >
          <i className={`${themeSetups[0]?.iconSetupProfile || "pe-7s-user"} icon-size`} />
        </button>
        {localStorage.getItem("isAccessToken") == 'true' && (
          <div ref={dropdownRef} className="account-dropdown">
            <ul>
              <>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/my-account"}>My Account</Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/order-history"}>Order History</Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/"} onClick={() => logOut()}>
                    Logout
                  </Link>
                </li>
              </>
            </ul>
          </div>
        )}
      </div>
      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  iconWhiteClass: PropTypes.string,
};



export default IconGroup;
