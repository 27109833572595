import clsx from "clsx";
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchSuggestBySearch } from '../../../store/slices/product-slice';



const MobileSearch = () => {
  const navigate = useNavigate();
  const { search } = useParams();
  const { pathname } = useLocation();
  const [query, setQuery] = useState(search || '');
  const [suggestions, setSuggestions] = useState([]);
  const { filterSuggest } = useSelector((state) => state.product);
  const { uiSetup } = useSelector((state) => state.theme);
  const searchBarRef = useRef(null);

  const dispatch = useDispatch();


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
        setQuery('');
        setSuggestions([]);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    navigate(`${process.env.PUBLIC_URL}/search/${query}`);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value.trim() === '') {
      setSuggestions([]);
      return;
    }
    const timeoutId = setTimeout(() => {
      dispatch(fetchSuggestBySearch(value.trim()));
      setSuggestions(filterSuggest.slice());
    }, 300);
    return () => clearTimeout(timeoutId);
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    navigate(`${process.env.PUBLIC_URL}/search/${suggestion}`);
    setSuggestions([]);
  };

  const closeMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.remove("active");
  };

  return (
    <div ref={searchBarRef} className="offcanvas-mobile-search-area">
      <form onSubmit={handleSubmit}>
        <input type="search" placeholder="Search ..."
          aria-label="Search"
          className='search-input'
          onChange={handleInputChange}
          value={query}
        />
        <button type="submit" id="mobile-menu-close-trigger"
          onClick={() => closeMobileMenu()}>
          <i className={uiSetup?.iconSetupSearch} style={{ marginRight: '-5px' }} />
        </button>
      </form>
      {suggestions?.length > 0 && (
        <ul className="list-group position-absolute start-0 mt-2">
          {suggestions?.map((suggestion, index) => (
            <li
              key={index}
              className={clsx("list-group-item pointer")}
              id="mobile-menu-close-trigger"
              onClick={() => {
                handleSuggestionClick(suggestion);
                closeMobileMenu();
              }}
            >
              {suggestion.split(' ').slice().join(' ')}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MobileSearch;
