import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import AppConsts from "../appconst";
import { useNavigate } from "react-router-dom";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: AppConsts.apiBaseUrl,
  headers: { "Content-Type": "application/json" },
});

let isRefreshing = false;
let failedQueue = [];

// Add failed requests to a queue
const addToQueue = (callback) => {
  return new Promise((resolve, reject) => {
    failedQueue.push({ resolve, reject });
  });
};

// Process the queue after the token refresh
const processQueue = (error, token = null) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });
  failedQueue = [];
};

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async (req) => {
   // Check if the request should bypass the token check
    if (req.headers["X-Bypass-Token-Check"]) {
      return req; // Bypass the token check for this request
    }

    const accessToken = localStorage.getItem("accessToken") || null;

    if (accessToken) {
      req.headers.Authorization = `Bearer ${accessToken}`;
    }

    try {
      if (accessToken) {
        const tokenData = jwt_decode(accessToken);
        const isExpired = dayjs.unix(tokenData.exp).diff(dayjs()) < 1;

        if (!isExpired) return req;

        if (!isRefreshing) {
          isRefreshing = true;
          const refreshToken = localStorage.getItem("refreshToken");
          if (!refreshToken) throw new Error("Refresh token missing");

          const response = await axios.post(
            `${AppConsts.apiBaseUrl}/Auth/refresh`,
            {
              accessToken,
              refreshToken,
              userId: localStorage.getItem("userId"),
              companyId: localStorage.getItem("companyId"),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (response.data && response.data.accessToken) {
            localStorage.setItem("accessToken", response.data.accessToken);
            localStorage.setItem("refreshToken", response.data.refreshToken);

            req.headers.Authorization = `Bearer ${response.data.accessToken}`;
            isRefreshing = false;
            processQueue(null, response.data.accessToken);
            return req;
          } else {
            throw new Error("Token refresh failed");
          }
        }

        console.log("Refresh in progress, queuing request...");
        return addToQueue((newToken) => {
          req.headers.Authorization = `Bearer ${newToken}`;
          return req;
        });
      }
    } catch (error) {
      console.error("Error during token check:", error);
      isRefreshing = false;
      processQueue(error, null);
      return Promise.reject(error);
    }

    return req;
  },
  (error) => {
    console.error("Request Error:", error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const navigate = useNavigate(); 
      navigate('/login-register');
      console.error("Unauthorized error, redirecting to login...");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
